import React, { Fragment, useState, useEffect, useRef } from "react";
import { Row, Col, Table, Input, Button, Form } from "reactstrap";
import { adminaxios } from "../../../axios";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";

const AllPlans = (props) => {
  const { formData } = props;
  const [plansList, setPlanList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [allplanscheck, setAllplanscheck] = useState("");
  useEffect(() => {
    setAllplanscheck("");
  }, [props.isformclose]);

  useEffect(() => {
    adminaxios
      .get(`accounts/area/${formData && formData.area}/plans`)
      .then((res) => {
        setFilteredData(res.data);
        setPlanList(res.data);
      })
      .catch((error) => console.log(error, "errors in getting service data"));
  }, [formData]);

  const handleSearchChange = (event) => {
    let value = event.target.value.toLowerCase();
    let result = plansList.filter((plan) =>
      plan.plan_cost.toString().includes(value) ||
      plan.package_name.toLowerCase().includes(value)
    );
    setFilteredData(result);
  };


  const planSelection = () => {
    const selectedPlan = plansList.find((item) => item.id === allplanscheck);
    console.log(selectedPlan,'selectedPlan')
    if (selectedPlan) {
      props.setSelectedPlan({
        ...selectedPlan,
        final_total_plan_cost: selectedPlan.total_plan_cost,
      });
      props.handleChangeFormInput({
        name: "plan_name",
        value: selectedPlan.package_name,
      });
      props.handleChangeFormInput({
        name: "service_plan",
        value: selectedPlan.id,
      });
      props.closeCustomizer();
      setAllplanscheck("");
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm="8">
          <Form>
            <input
              className="form-control"
              type="text"
              placeholder="Search for Plan or Enter Amount"
              onChange={handleSearchChange}
              style={{
                border: "1px solid #ced4da",
                backgroundColor: "white",
                marginLeft: "22px",
              }}
            />
          </Form>
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm="12">
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th></th>
                <th>Plan Name</th>
                <th>Validity</th>
                <th>Plan Cost</th>
                <th>Speed / OTT’s List</th>
                <th>Total Plan Cost</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <tr key={item.id}>
                  <td style={{ paddingLeft: "46px" }}>
                    <Input
                      type="radio"
                      name="planSelection"
                      onClick={() => setAllplanscheck(item.id)}
                      checked={allplanscheck === item.id}
                    />
                  </td>
                  <td>{item.package_name}</td>
                  <td>{`${item.time_unit} ${item.unit_type}`}</td>
                  <td>₹ {parseFloat(item.plan_cost).toFixed(2)}</td>
                  <td>{`${item.download_speed} Mbps`}</td>
                  <td>₹ {parseFloat(item.total_plan_cost).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="justify-content-end" style={{ marginTop: "20px" }}>
        <Col sm="auto">
          <Button color="secondary" onClick={props.closeCustomizer}>Cancel</Button>
        </Col>
        <Col sm="auto">
          <Button
            color="primary"
            onClick={planSelection}
            disabled={allplanscheck === ""}
          >
            OK
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { formData, showCustomizer, selectedPlan, service, errors, startDate } = state.KYCForm;
  return {
    formData,
    showCustomizer,
    selectedPlan,
    service,
    errors,
    startDate,
  };
};

export default connect(mapStateToProps)(AllPlans);
