import React, { useEffect } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";

const OTTServiceDetailsForm = ({ formData, handleInputChange, handleInputBlur, planData, ottSelectPlan }) => {


    useEffect(() => {
        if (ottSelectPlan) {
            const selectedPlan = planData.find(plan => plan.plan_name === ottSelectPlan);
            if (selectedPlan) {
                // console.log(selectedPlan, 'selectedPlan-ott')
                handleInputChange({ target: { name: "ott_service", value: selectedPlan.plan_id } });
                handleInputChange({ target: { name: "OTT_plan_name", value: selectedPlan.plan_name } });
                handleInputChange({ target: { name: "OTT_plan_duration", value: selectedPlan.plan_duration } });
                handleInputChange({ target: { name: "OTT_ott_services", value: selectedPlan?.ott_services?.length } });
                handleInputChange({ target: { name: "OTT_plan_cost", value: selectedPlan.plan_cost } });
                handleInputChange({ target: { name: "OTT_plan_SGST", value: (selectedPlan.tax / 2).toFixed(2) } });
                handleInputChange({ target: { name: "OTT_plan_CGST", value: (selectedPlan.tax / 2).toFixed(2) } });
                handleInputChange({ target: { name: "OTT_total_plan_cost", value: selectedPlan.total } });
                // handleInputChange({ target: { name: "plan_name", value: selectedPlan.plan_name } });
                // handleInputChange({ target: { name: "service_plan", value: selectedPlan.plan_id } });
                formData.plan_name =  selectedPlan.plan_name;
                // formData.service_plan =  selectedPlan.plan_id;
                formData.service_plan =  selectedPlan.id;
             
                
            }
        }
    }, [ottSelectPlan]);

  if(planData && planData.length > 0){
    formData.plan_name =  planData[0].plan_name;
    // formData.service_plan =  planData[0].plan_id;
    formData.service_plan =  planData[0].id;
   
  }

  console.log(formData.plan_name, "plan name")
  console.log(formData.service_plan, "formData.service_plan")

   

    return (
        <>
            {planData?.length > 0 ? (
                <div>
                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="OTT_plan_name">Plan *</Label>
                                <Input
                                    type="select"
                                    name="OTT_plan_name"
                                    id="OTT_plan_name"
                                    value={formData.OTT_plan_name || ""}
                                    onChange={(event) => {
                                        handleInputChange(event);
                                        const selectedPlan = planData.find(
                                            (plan) => plan.plan_name === event.target.value
                                        );
                                        if (selectedPlan) {
                                            console.log(selectedPlan, 'selectedPlan-ott')


                                            handleInputChange({ target: { name: "ott_service", value: selectedPlan.plan_id } });
                                            handleInputChange({
                                                target: { name: "OTT_plan_name", value: selectedPlan.plan_name },
                                            });
                                            handleInputChange({
                                                target: { name: "OTT_plan_duration", value: selectedPlan.plan_duration },
                                            });
                                            handleInputChange({
                                                target: { name: "OTT_ott_services", value: selectedPlan?.ott_services?.length },
                                            });
                                            handleInputChange({
                                                target: { name: "OTT_plan_cost", value: selectedPlan.plan_cost },
                                            });
                                            handleInputChange({
                                                target: { name: "OTT_plan_SGST", value: (selectedPlan.tax / 2).toFixed(2) },
                                            });
                                            handleInputChange({
                                                target: { name: "OTT_plan_CGST", value: (selectedPlan.tax / 2).toFixed(2) },
                                            });
                                            handleInputChange({
                                                target: { name: "OTT_total_plan_cost", value: selectedPlan.total },
                                            });
                                        }
                                    }}
                                    onBlur={handleInputBlur}
                                >
                                    <option value="">Select a Plan</option>
                                    {planData.map((plan) => (
                                        <option key={plan.plan_id} value={plan.plan_name}>
                                            {plan.plan_name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="OTT_plan_duration">Pack Validity *</Label>
                                <Input
                                    type="text"
                                    name="OTT_plan_duration"
                                    id="OTT_plan_duration"
                                    value={formData.OTT_plan_duration || ""}
                                    onBlur={handleInputBlur}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="OTT_ott_services">OTT's Lists</Label>
                                <Input
                                    type="text"
                                    name="OTT_ott_services"
                                    id="OTT_ott_services"
                                    value={formData?.OTT_ott_services || ""}
                                    onBlur={handleInputBlur}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="OTT_plan_cost">Plan Cost</Label>
                                <Input
                                    type="text"
                                    name="OTT_plan_cost"
                                    id="OTT_plan_cost"
                                    value={formData.OTT_plan_cost || ""}
                                    onBlur={handleInputBlur}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row form>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="OTT_plan_SGST">Plan SGST</Label>
                                <Input
                                    type="text"
                                    name="OTT_plan_SGST"
                                    id="OTT_plan_SGST"
                                    value={formData.OTT_plan_SGST || ""}
                                    onBlur={handleInputBlur}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="OTT_plan_CGST">Plan CGST</Label>
                                <Input
                                    type="text"
                                    name="OTT_plan_CGST"
                                    id="OTT_plan_CGST"
                                    value={formData.OTT_plan_CGST || ""}
                                    onBlur={handleInputBlur}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for="OTT_total_plan_cost">Total Cost</Label>
                                <Input
                                    type="text"
                                    name="OTT_total_plan_cost"
                                    id="OTT_total_plan_cost"
                                    value={formData.OTT_total_plan_cost || ""}
                                    onBlur={handleInputBlur}
                                    disabled
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <p>There are no plans available for selected Area</p>
                </div>
            )}
        </>
    );
};

export default OTTServiceDetailsForm;
