import React, { useState } from 'react';
import { Table, Button, Input, FormGroup, Label } from 'reactstrap';

const AllOTTPlans = ({ setSelectedPlan, planData, closeCustomizer }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [selectedPlanName, setSelectedPlanName] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSelectPlan = (planId, planName) => {
        setSelectedPlanId(planId);
        setSelectedPlanName(planName);
    };

    const handleReset = () => {
        setSelectedPlanId(null);
        setSelectedPlanName('');
        setSearchTerm('');
        closeCustomizer();
    };

    const filteredPlans = planData.filter((plan) =>
        plan.plan_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            <Input
                type="text"
                placeholder="Search for Plan or Enter Amount"
                value={searchTerm}
                onChange={handleSearch}
                className="mb-3"
            />
            <Table bordered hover>
                <thead>
                    <tr>
                        <th></th>
                        <th>Plan Name</th>
                        <th>Validity</th>
                        <th>Plan Cost</th>
                        <th>OTT Services</th>
                        <th>Total Plan Cost</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredPlans.map((plan) => (
                        <tr key={plan.plan_id}>
                            <td>
                                <FormGroup check style={{ marginTop: '-15px' }}>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="plan"
                                            checked={selectedPlanId === plan.plan_id}
                                            onChange={() => handleSelectPlan(plan.plan_id, plan.plan_name)}
                                        />
                                    </Label>
                                </FormGroup>
                            </td>
                            <td>{plan.plan_name}</td>
                            <td>{`${plan.plan_duration} Month${plan.plan_duration > 1 ? 's' : ''}`}</td>
                            <td>Rs. {plan.plan_cost.toFixed(2)}/-</td>
                            <td>{plan.ott_services.length} OTTs</td>
                            <td>Rs. {plan.total.toFixed(2)}/-</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div style={{ display: 'flex', columnGap: '20px', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button color="secondary" onClick={handleReset} className="me-2">
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        setSelectedPlan(selectedPlanName);
                        handleReset();
                    }}
                    disabled={!selectedPlanId}
                >
                    OK
                </Button>
            </div>
        </div>
    );
};

export default AllOTTPlans;
