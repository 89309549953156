import React from "react";

const tableCellStyle = {
  textAlign: "center",
  paddingRight: 0,
  paddingLeft: 0,
};

const Row = ({ row, onSelectRow, serviceIdClickHandler }) => {
  return (
    <tr key={row.id}>
      <td
        style={{
          textTransform: "uppercase",
          ...tableCellStyle,
          textAlign: "left",
          paddingLeft: 40,
        }}
      >
        <input
          type="checkbox"
          checked={!!row.isSelected}
          onChange={() => onSelectRow(row, !row.isSelected)}
        />
        <span className="divider" />
        <span onClick={() => serviceIdClickHandler(row)}>
          {row.combo_plan_name}
        </span>
      </td>
      <td style={{ ...tableCellStyle, color: 'black' }}>{row.internet_plan_name}</td>
      <td style={tableCellStyle}>{row.ott_plan_name || "TBA"}</td>
      <td style={tableCellStyle}>{row.plan_status}</td>
      <td style={tableCellStyle}> ₹ {parseFloat(row.total).toFixed(2)}</td>
    </tr>
  );
};

export default Row;
