const tableHeaders = [
  { name: "Package Name", sortable: true },
  { name: "Plan Code", sortable: false },
  { name: "Duration", sortable: true },
  { name: "Status", sortable: false },
  // { name: "Plan Cost", sortable: true },
  // { name: "Plan CGST", sortable: true },
  // { name: "Plan SGST", sortable: true },
  // { name: "Franchise Name", sortable: false },
  { name: "Total Cost", sortable: true },
  { name: "OTT Count", sortable: false },
];

export default tableHeaders;
