import React, { useEffect } from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";

const ComboServiceDetailsForm = ({
  formData,
  handleInputChange,
  handleInputBlur,
  comboData,
  comboSelectPlan,
}) => {
  useEffect(() => {
    if (comboSelectPlan) {
      const selectedPlan = comboData.find(
        (plan) => plan.combo_plan_name === comboSelectPlan
      );
      if (selectedPlan) {
        handleInputChange({
          target: {
            name: "combo_plan_name",
            value: selectedPlan.combo_plan_name,
          },
        });
        handleInputChange({
          target: {
            name: "combo_plan_duration",
            value: selectedPlan.plan_duration,
          },
        });
        handleInputChange({
          target: {
            name: "combo_upload_speed",
            value: selectedPlan.internet_plan_details.upload_speed,
          },
        });
        handleInputChange({
          target: {
            name: "combo_download_speed",
            value: selectedPlan.internet_plan_details.download_speed,
          },
        });
        handleInputChange({
          target: {
            name: "combo_data_limit",
            value: selectedPlan.internet_plan_details.data_limit,
          },
        });
        handleInputChange({
          target: {
            name: "combo_ott_services",
            value: selectedPlan?.ott_services?.length,
          },
        });
        handleInputChange({
          target: { name: "combo_plan_cost", value: selectedPlan.basic },
        });
        handleInputChange({
          target: {
            name: "combo_plan_SGST",
            value: (selectedPlan.tax / 2).toFixed(2),
          },
        });
        handleInputChange({
          target: {
            name: "combo_plan_CGST",
            value: (selectedPlan.tax / 2).toFixed(2),
          },
        });
        handleInputChange({
          target: { name: "combo_total_plan_cost", value: selectedPlan.total },
        });
        handleInputChange({
          target: { name: "combo_service", value: selectedPlan.plan_id },
        });
      }
    }
  }, [comboSelectPlan]);

  return (
    <>
      {comboData?.length > 0 ? (
        <div>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="plan">Plan *</Label>
                <Input
                  type="select"
                  name="combo_plan_name"
                  id="combo_plan_name"
                  value={formData.combo_plan_name || ""}
                  onChange={(event) => {
                    handleInputChange(event);
                    const selectedPlan = comboData.find(
                      (plan) => plan.combo_plan_name === event.target.value
                    );
                    if (selectedPlan) {
                      handleInputChange({
                        target: {
                          name: "combo_plan_name",
                          value: selectedPlan.combo_plan_name,
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_plan_duration",
                          value: selectedPlan.plan_duration,
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_upload_speed",
                          value:
                            selectedPlan.internet_plan_details.upload_speed,
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_download_speed",
                          value:
                            selectedPlan.internet_plan_details.download_speed,
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_data_limit",
                          value: selectedPlan.internet_plan_details.data_limit,
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_ott_services",
                          value: selectedPlan?.ott_services?.length,
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_plan_cost",
                          value: selectedPlan.basic,
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_plan_SGST",
                          value: (selectedPlan.tax / 2).toFixed(2),
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_plan_CGST",
                          value: (selectedPlan.tax / 2).toFixed(2),
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_total_plan_cost",
                          value: selectedPlan.total,
                        },
                      });
                      handleInputChange({
                        target: {
                          name: "combo_service",
                          value: selectedPlan.plan_id,
                        },
                      });
                    }
                  }}
                  onBlur={handleInputBlur}
                >
                  <option value="">Select a Plan</option>
                  {comboData.map((plan) => (
                    <option key={plan.plan_id} value={plan.combo_plan_name}>
                      {plan.combo_plan_name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_plan_duration">Pack Validity *</Label>
                <Input
                  type="text"
                  name="combo_plan_duration"
                  id="combo_plan_duration"
                  value={formData.combo_plan_duration || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_upload_speed">Upload Speed *</Label>
                <Input
                  type="text"
                  name="combo_upload_speed"
                  id="combo_upload_speed"
                  value={formData.combo_upload_speed || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_download_speed">Download Speed *</Label>
                <Input
                  type="text"
                  name="combo_download_speed"
                  id="combo_download_speed"
                  value={formData.combo_download_speed || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_data_limit">Data Limit</Label>
                <Input
                  type="text"
                  name="combo_data_limit"
                  id="combo_data_limit"
                  value={formData.combo_data_limit || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_ott_services">OTT's List</Label>
                <Input
                  type="text"
                  name="combo_ott_services"
                  id="combo_ott_services"
                  value={formData.combo_ott_services || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_plan_cost">Plan Cost</Label>
                <Input
                  type="text"
                  name="combo_plan_cost"
                  id="combo_plan_cost"
                  value={formData.combo_plan_cost || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_plan_SGST">Plan SGST</Label>
                <Input
                  type="text"
                  name="combo_plan_SGST"
                  id="combo_plan_SGST"
                  value={formData.combo_plan_SGST || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_plan_CGST">Plan CGST</Label>
                <Input
                  type="text"
                  name="combo_plan_CGST"
                  id="combo_plan_CGST"
                  value={formData.combo_plan_CGST || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_total_plan_cost">Total Cost</Label>
                <Input
                  type="text"
                  name="combo_total_plan_cost"
                  id="combo_total_plan_cost"
                  value={formData.combo_total_plan_cost || ""}
                  onBlur={handleInputBlur}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="combo_mac_id">MAC ID</Label>
                <Input
                  type="text"
                  name="combo_mac_id"
                  id="combo_mac_id"
                  value={formData.combo_mac_id || ""}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p>There are no plans available for selected Area</p>
        </div>
      )}
    </>
  );
};

export default ComboServiceDetailsForm;
