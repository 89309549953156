import React from "react";
import AllPlans from "../allplans";
import AllOTTPlans from "./AllOTTPlans";
import AllComboPlans from "./ServiceDetails/AllComboPlans";

const AllPlansRightSidePanel = (props) => {
  const { closeCustomizer } = props;
  return (
    <div className="customizer-contain">
      <div className="tab-content" id="c-pills-tabContent">
        <div
          className="customizer-header"
          style={{ padding: "0px", border: "none" }}
        >
          <br />
          <div id="headerheading" style={{ marginTop: "-12px" }}>
            {" "}
            All Plans
          </div>
          <i className="icon-close" onClick={closeCustomizer}></i>
          <br />
        </div>
        <div className=" customizer-body custom-scrollbar">
          {
            props.selectedOption === "Internet" ?
              <AllPlans
                setSelectedPlan={props.setSelectedPlan}
                handleChangeFormInput={props.handleChangeFormInput}
                closeCustomizer={closeCustomizer}
                isformclose={props.isformclose}
                selectedOption={props.selectedOption}
              /> : props.selectedOption === "OTT" ? <AllOTTPlans setSelectedPlan={props.setOttSelectPlan} planData={props.planData} isformclose={props.isformclose} closeCustomizer={closeCustomizer}
              /> : props.selectedOption === "Combo" ? <AllComboPlans setSelectedPlan={props.setComboSelectPlan} comboData={props.comboData} isformclose={props.isformclose} closeCustomizer={closeCustomizer}
              /> : ''
          }
        </div>
      </div>
    </div>
  );
};

export default AllPlansRightSidePanel;
