import React from "react";
import OttIcon from "../../../../assets/images/ott-icon.png";

const tableCellStyle = {
  textAlign: "center",
  paddingRight: 0,
  paddingLeft: 0,
};

const Row = ({ row, onSelectRow, serviceIdClickHandler }) => {
  return (
    <tr key={row.id}>
      <td style={tableCellStyle}>
        <span onClick={() => serviceIdClickHandler(row)}>
          {row.operator_code}
        </span>
      </td>
      <td style={{ ...tableCellStyle, color: "black" }}>{row.operator_name}</td>
      <td style={tableCellStyle}>{row.balance}</td>
    </tr>
  );
};

export default Row;
