import React, { Fragment, useEffect, useState, useRef } from "react"; //hooks
import { useParams, Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Label,
    FormGroup,
    Table,
    ModalFooter,
    Button,
    Input,
    ModalBody,
    Modal,
    Spinner,
    ModalHeader,
} from "reactstrap";
import { servicesaxios, franchiseaxios, ottaxios } from "../../../../axios";
import { toast } from "react-toastify";
import { Tooltip } from "antd";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

const OTTAssignedPackage = ({
    initialValues,
    loaderSpinneer,
    setLoaderSpinner,
    allserviceplanobj,
    setAllServiceplanobj,
    allServicePlanObjCopy,
    setAllServicePlanObjCopy,
    selectserviceobj,
    setSelectServiceobj,
    Allplanstoggle,
    lead,
    onUpdate,
    isDisabled,
    setLead,
    setIsdisabled,
    leadUser,
    setLeadUser,
    datasubmit,
    AnotherToggle,
    setSelectServiceobjnew,
    selectserviceobjnew,
    clicked,
    selectedType
}) => {

    const [errors, setErrors] = useState({});

    const [userSelectedService, setUserSelectedService] = useState([]);
    const [newlySelectedService, setNewlySelectedService] = useState([]);
    const [resetfield, setResetfield] = useState(false);
    const [inputs, setInputs] = useState(initialValues);
    const [resetStatus, setResetStatus] = useState(false);

    const [alertModel, setAlertModel] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [formData, setFormData] = useState({});


    const [ottPlanDetails, setOttPlanDetails] = useState([]);
    const [allOttPlanDetails, setAllOttPlanDetails] = useState([]);
    const [initialAllOttPlanDetails, setInitialAllOttPlanDetails] = useState([]);
    const [originalPlans, setOriginalPlans] = useState([]);
    const [servicelist, setServicelist] = useState({});

    const getOttFranchiseDetails = async () => {
        try {
            const ottAssignedPackages = await ottaxios.get(`/combo/get_shr_detils?id=${lead.id}`);
            if (ottAssignedPackages?.data?.status === "success") {
                setOttPlanDetails(ottAssignedPackages?.data?.data);
                setOriginalPlans(ottAssignedPackages?.data?.data);
                const modifiedData = ottAssignedPackages?.data?.data.reduce((acc, item) => {
                    if (item?.plan) {
                        acc[item.plan] = item;
                    }
                    return acc;
                }, {});
                if (modifiedData) {
                    setServicelist(modifiedData);
                }
            }
        } catch (error) {
            console.error("Error checking franchise registration:", error);
        }
    };

    const getExportData = async () => {
        try {
            const data = await ottaxios.get("combo/get_plans?export=true");
            if (data?.data?.status === "success") {
                setAllOttPlanDetails(data?.data?.data?.results);
                setInitialAllOttPlanDetails(data?.data?.data?.results)
            }
        } catch (error) {
            toast.error(error.response.data?.message);
        }
    }

    useEffect(() => {
        getExportData();
    }, []);

    useEffect(() => {
        if (lead?.id) {
            getOttFranchiseDetails();
        }
    }, [lead]);



    const formattedData_gst_codes = lead?.gst_codes?.map((item) => item.id);

    useEffect(() => {
        if (lead) {
            let plans =
                originalPlans &&
                originalPlans?.map((plan, index) => ({
                    id: `${index}_${plan.plan}`,
                    ...plan,
                }));

            setFormData((prev) => ({
                ...prev,
                address: {
                    ...lead.address,
                },
                leadDetailsForInputs: { ...lead, plans },
            }));
        }
    }, [lead, originalPlans]);

    const onSaveplans = (e, id) => {
        e.preventDefault();
        let data = { ...formData, plans: formData.leadDetailsForInputs.plans };
        console.log(data, "i am data");
        delete data["leadDetailsForInputs"];

        let plandata = data.plans.map((item, index) => {
            delete item.total_plan_cost;
            delete item.plan_name;
            delete item.validity;
            delete item.id;
            return item;
        });
        data = { ...data, plans: plandata };

        let plans = [];
        for (const key in servicelist) {
            // if(!servicelist[key].disabled){
            if (servicelist[key].selected) {
                plans.push({
                    plan_id: parseInt(key),
                    isp_share: servicelist[key].isp_share,
                    franchise_share: servicelist[key].franchise_share,
                    tax_type: servicelist[key].tax_type,
                });
            }
        }

        let submitdata = { ...data, franchise_id: lead?.id };
        if (plans.length > -1) {
            submitdata = { ...data, plans, franchise_id: lead?.id };
        }
        submitdata.gst_codes = formattedData_gst_codes;
        setLoaderSpinner(true);
        ottaxios
            .post(`combo/update_plans`, submitdata)
            .then((res) => {
                toast.success("Combo Franchise was edited successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                getOttFranchiseDetails();
                Allplanstoggle();
                setNewlySelectedService([]);
                setIsdisabled(true);
                setServicelist({});
                setLoaderSpinner(false);
            })

            .catch((error) => {
                console.log("error");
                setLoaderSpinner(false);
                setErrorMsg(error.response.data["detail"]);
                setAlertModel(true);
                console.log(error.response.data["detail"]);
            });
    };

    const handleSubmit = (e, id) => {
        e.preventDefault();
        setAlertModel(false);
        let data = { ...formData, plans: formData.leadDetailsForInputs.plans };
        delete data["leadDetailsForInputs"];

        let plandata = data.plans.map((item, index) => {
            delete item.total_plan_cost;
            delete item.plan_name;
            delete item.validity;
            delete item.id;
            return item;
        });
        data = { ...data, plans: plandata };

        let plans = [];
        for (const key in servicelist) {
            // if(!servicelist[key].disabled){
            if (servicelist[key].selected) {
                plans.push({
                    plan_id: parseInt(key),
                    isp_share: servicelist[key].isp_share,
                    franchise_share: servicelist[key].franchise_share,
                    tax_type: servicelist[key].tax_type,
                });
            }
        }

        let submitdata = { ...data, franchise_id: lead?.id };
        if (plans.length > -1) {
            submitdata = { ...data, plans, franchise_id: lead?.id };
        }
        submitdata.gst_codes = formattedData_gst_codes;
        setLoaderSpinner(true);
        ottaxios
            .post(`/combo/update_plans`, submitdata)
            .then((res) => {
                setLoaderSpinner(false);
                console.log(res);
                console.log(res.data);
                onUpdate(res.data);
                toast.success("Combo Franchise was edited successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                getOttFranchiseDetails();
                Allplanstoggle();
                setNewlySelectedService([]);
                setIsdisabled(true);
                setServicelist({});
            })
            .catch(function (error) {
                setLoaderSpinner(false);
                toast.error("Please fill all required fields", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                console.error("Something went wrong!", error);
            });
    };

    const handleSubmitedit = (e, id) => {
        e.preventDefault();
        setAlertModel(false);
    
        // Copy form data and plans array
        let data = { ...formData, plans: formData.leadDetailsForInputs.plans };
        delete data["leadDetailsForInputs"];
    
        // Filter and clean up each plan item
        let plandata = data.plans.map((item) => {
            delete item.total_plan_cost;
            delete item.plan_name;
            delete item.validity;
            delete item.id;
            return item;
        });
        data = { ...data, plans: plandata };
    
        // Prepare plans data
        let plans = [];
        for (const key in servicelist) {
            if (servicelist[key].selected) {
                plans.push({
                    plan_id: parseInt(key),
                    isp_share: servicelist[key].isp_share,
                    franchise_share: servicelist[key].franchise_share,
                    tax_type: servicelist[key].tax_type,
                });
            }
        }
    
        // Prepare final submission data
        let submitdata = { ...data, franchise_id: lead?.id };
        if (plans.length > -1) {
            submitdata = { ...data, plans, franchise_id: lead?.id };
        }
        submitdata.gst_codes = formattedData_gst_codes;
    
        // Submit data using POST instead of PATCH
        setLoaderSpinner(true);
        ottaxios
            .post(`/combo/update_plans`, submitdata)
            .then((res) => {
                setLoaderSpinner(false);
                console.log(res);
                console.log(res.data);
                onUpdate(res.data);
                toast.success("Combo Franchise was edited successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                AnotherToggle();
                setNewlySelectedService([]);
                setIsdisabled(true);
                setServicelist({});
            })
            .catch((error) => {
                setLoaderSpinner(false);
                toast.error("Please fill all required fields", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 1000,
                });
                console.error("Something went wrong!", error);
            });
    };
    
    //pop up for revenue sharing
    function checkEmptyValue(e) {
        if (e.target.value == "") {
            e.target.classList.remove("not-empty");
        } else {
            e.target.classList.add("not-empty");
        }
    }
    const handleClose = () => {
        Allplanstoggle();
        const newlySelectedIDs = newlySelectedService.map((srvc) => srvc.id + "");
        console.log(newlySelectedIDs);
        setUserSelectedService(
            userSelectedService.filter((srvc) => !newlySelectedIDs.includes(srvc.id)),
        );
        const currentServiceList = { ...servicelist };
        for (const key in currentServiceList) {
            console.log(typeof key);
            console.log(typeof newlySelectedIDs[0]);
            if (newlySelectedIDs.includes(key)) {
                delete currentServiceList[key];
            }
        }
        console.log(currentServiceList);
        setServicelist({ ...currentServiceList });
        setNewlySelectedService([]);
        setFormData((prevState) => {
            const prevPlans = { ...prevState.plans };
            for (let serviceId of newlySelectedIDs) {
                delete prevPlans[`isp_share_${serviceId}`];
                delete prevPlans[`franchise_share_${serviceId}`];
                delete prevPlans[`tax_type_${serviceId}`];
            }

            return {
                ...prevState,
                plans: {
                    ...prevPlans,
                },
            };
        });
        setErrors({});
    };
    const handleCloseEdit = () => {
        AnotherToggle();
        const newlySelectedIDs = newlySelectedService.map((srvc) => srvc.id + "");
        console.log(newlySelectedIDs);
        setUserSelectedService(
            userSelectedService.filter((srvc) => !newlySelectedIDs.includes(srvc.id)),
        );
        const currentServiceList = { ...servicelist };
        for (const key in currentServiceList) {
            console.log(typeof key);
            console.log(typeof newlySelectedIDs[0]);
            if (newlySelectedIDs.includes(key)) {
                delete currentServiceList[key];
            }
        }
        console.log(currentServiceList);
        setServicelist({ ...currentServiceList });
        setNewlySelectedService([]);
        setFormData((prevState) => {
            const prevPlans = { ...prevState.plans };
            for (let serviceId of newlySelectedIDs) {
                delete prevPlans[`isp_share_${serviceId}`];
                delete prevPlans[`franchise_share_${serviceId}`];
                delete prevPlans[`tax_type_${serviceId}`];
            }

            return {
                ...prevState,
                plans: {
                    ...prevPlans,
                },
            };
        });
        setErrors({});
    };

    // validation for tax_type
    const InputFieldvalidate = (name, value) => {
        if (value > 100) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: "no greater than 100",
                };
            });
        } else if (!/^[0-9]\d{0,9}(\.\d{1,3})?%?$/.test(value)) {
            {
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        [name]: "not valid",
                    };
                });
            }
        } else if (!value) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: "Field is required",
                };
            });
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: "",
                };
            });
        }
    };
    // filter
    const handlesearchplan = (event) => {
        let value = event.target.value.toLowerCase();
        let result = [];

        result = initialAllOttPlanDetails?.filter((data) => {
            return (
                data?.total?.toString().includes(value) ||
                data?.combo_plan_name?.toLowerCase().includes(value)
            );
        });

        setAllOttPlanDetails(result);
    };


    const handlesearchassignedplan = (event) => {
        let value = event.target.value.toLowerCase();
        let result = [];

        if (ottPlanDetails && ottPlanDetails.length > 0) {
            result = ottPlanDetails.filter((data) => {
                return (
                    data.plan_name.toLowerCase().includes(value) ||
                    data.plan_cost.toString().toLowerCase().includes(value)
                );
            });
        }

        if (value) {
            setOttPlanDetails(result);
        } else {
            setOttPlanDetails(originalPlans);
        }
    };


    const searchInputField = useRef(null);

    const handleInputChange = (event, isAddress = false, serviceId) => {
        if (!event) return;

        event.persist();

        setResetfield(false);
        setResetStatus(false);

        const target = event.target;
        const value = target.value;
        const name = target.name;

        console.log({ target, value, name })
        if (name.includes("isp_share") || name.includes("franchise_share") || name.includes("tax_type")) {
            let serviceListObj = servicelist[serviceId] || {};

            if (name.includes("tax_type")) {
                setErrors(prevState => ({
                    ...prevState,
                    [`tax_type_${serviceId}`]: "",
                }));
                serviceListObj = { ...serviceListObj, tax_type: value };
            } else {
                // Handle isp_share and franchise_share
                if (name === `isp_share_${serviceId}`) {
                    serviceListObj = {
                        ...serviceListObj,
                        isp_share: value,
                        franchise_share: value ? (100 - parseFloat(value)).toFixed(0) : ""
                    };
                } else if (name === `franchise_share_${serviceId}`) {
                    serviceListObj = {
                        ...serviceListObj,
                        franchise_share: value,
                        isp_share: value ? (100 - parseFloat(value)).toFixed(0) : ""
                    };
                }

                // Validate input fields after updating serviceListObj
                InputFieldvalidate(`isp_share_${serviceId}`, serviceListObj.isp_share);
                InputFieldvalidate(`franchise_share_${serviceId}`, serviceListObj.franchise_share);
            }

            setServicelist(prevState => ({
                ...prevState,
                [serviceId]: serviceListObj,
            }));
        }

        if (name.includes("package_name")) {
            const selectedIDObj = allOttPlanDetails?.find(servicePlan => servicePlan.id === serviceId);

            if (selectedIDObj) {
                let currentSelected = [...userSelectedService];
                let newlySelected = [...newlySelectedService];

                if (target.checked) {
                    // Add to selected services if not already selected
                    if (!currentSelected.some(srvc => srvc.id === serviceId)) {
                        currentSelected.push(selectedIDObj);
                        newlySelected.push(selectedIDObj);
                    }

                    setServicelist(prevState => ({
                        ...prevState,
                        [serviceId]: {
                            ...prevState[serviceId],
                            selected: true,
                        },
                    }));
                } else {
                    // Remove from selected services if unchecked
                    currentSelected = currentSelected.filter(srvc => srvc.id !== serviceId);
                    newlySelected = newlySelected.filter(srvc => srvc.id !== serviceId);

                    setServicelist(prevState => ({
                        ...prevState,
                        [serviceId]: {
                            ...prevState[serviceId],
                            selected: false,
                        },
                    }));

                    setFormData(prevState => {
                        const updatedPlans = { ...prevState.plans };
                        delete updatedPlans[`isp_share_${serviceId}`];
                        delete updatedPlans[`franchise_share_${serviceId}`];
                        delete updatedPlans[`tax_type_${serviceId}`];

                        return {
                            ...prevState,
                            plans: updatedPlans,
                        };
                    });

                    setErrors(prevState => ({
                        ...prevState,
                        [`isp_share_${serviceId}`]: "",
                        [`franchise_share_${serviceId}`]: "",
                        [`tax_type_${serviceId}`]: "",
                    }));
                }

                setUserSelectedService(currentSelected);
                setNewlySelectedService(newlySelected);
            }
        }
    };



    const getIsDisabled = (service) => {
        const currentObj = servicelist[service];
        if (currentObj) {
            if (currentObj.selected) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };

    const tableStyles = {
        width: '100%',
        borderCollapse: 'collapse',
        backgroundColor: 'white',
        borderRadius: '0px',
        overflow: 'hidden',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
        maxWidth: '1200px',
        margin: '0 auto'
    };

    const headerCellStyles = {
        backgroundColor: '#E7EBF8',
        padding: '12px 20px',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: '500',
        color: '#374151',
        borderBottom: '1px solid #E5E7EB',
        borderRight: '1px solid #C8C7C7'
    };

    const lastHeaderCellStyles  = {
        ...headerCellStyles,
        borderRight: "none"
    }

    const cellStyles = {
        padding: '12px 20px',
        fontSize: '14px',
        color: '#374151',
        backgroundColor: 'white',
        borderBottom: '1px solid #E5E7EB',
        borderRight: '1px solid #C8C7C7'
    };

    const lastCellStyles  = {
        ...cellStyles,
        borderRight: "none"
    }

    const validityCostStyles = {
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    };

    
    const borderTable = {
        border: '1px solid #C8C7C7 !important'
    }

    console.log(servicelist, 'servicelist')

    return (
        <Fragment>
            <Button
                style={{
                    marginTop: "10px",
                    fontWeight: 600,
                    marginBottom: "15px"
                }}
                color="primary"
                className="save_button"
                id="save_button_loader"
                onClick={(e) => {
                    // datasubmit(e);
                    Allplanstoggle();
                }}
            >
                {"Add/Remove plans"}
            </Button>
            <Modal
                isOpen={selectserviceobj}
                toggle={Allplanstoggle}
                centered
                style={{ maxWidth: "1000px" }}
            >
                <ModalBody style={{ maxHeight: "500px", overflow: "auto", padding: "20px" }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                        <h3 style={{ margin: 0, fontWeight: "bold", color: "#333" }}>Add/Remove Plans</h3>
                        <Paper
                            component="div"
                            sx={{
                                p: "2px 4px",
                                display: "flex",
                                alignItems: "center",
                                width: 400,
                            }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                inputProps={{ "aria-label": "search google maps" }}
                                placeholder="Search for Plan or Enter Amount"
                                onChange={(event) => handlesearchplan(event)}
                            />
                            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                    </Stack>
                    <Table className="table-striped table-bordered table" >
                        <thead>
                            <tr>
                                <th scope="col" style={{width: "16%",  ...borderTable}}>{"Combo Plan Name"}</th>
                                <th scope="col" style={{ width: "22%",  ...borderTable }}>
                                    {"Validity / Plan Cost"}
                                </th>
                                <th scope="col" style={{ width: "17%", ...borderTable }}>
                                    {"Tax *"}
                                </th>
                                <th style={{ borderTable }} scope="col">{"ISP Sharing (%)"}</th>
                                <th style={{ borderTable }} scope="col">{"Franchise Sharing (%)"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allOttPlanDetails && allOttPlanDetails?.map((services) => (
                                <tr>
                                    <td style={{borderTable}} scope="row">
                                        {" "}
                                        <>
                                            <Label className="d-block" for="edo-ani1">
                                                <Input
                                                    className="checkbox_animated"
                                                    type="checkbox"
                                                    id="edo_ani1"
                                                    key={services.id}
                                                    value={services.id}
                                                    checked={
                                                        servicelist[services.id] &&
                                                        servicelist[services.id].selected
                                                    }
                                                    name={`package_name_${services.id}`}
                                                    onChange={(e) =>
                                                        handleInputChange(e, false, services.id)
                                                    }
                                                />
                                                {services.combo_plan_name}
                                            </Label>
                                        </>
                                    </td>
                                    <td style={{borderTable }}>
                                        {/* <tr> */}
                                            <span>
                                                {services.plan_duration && `${services.plan_duration} mon(s)`}
                                                {" / "}

                                            </span>

                                            <span>
                                                {" "}
                                                ₹ {parseFloat(services.total).toFixed(2)}
                                            </span>
                                        {/* </tr> */}
                                    </td>

                                    <td style={{borderTable}}>
                                        <FormGroup style={{margin: "0px"}}>
                                            <div className="input_wrap">
                                                <Input
                                                    type="select"
                                                    name={`tax_type_${services.id}`}
                                                    className={`form-control digits ${servicelist &&
                                                        servicelist[services.id] &&
                                                        servicelist[services.id].tax_type
                                                        ? "not-empty"
                                                        : "not-empty"
                                                        }`}
                                                    onChange={(e) =>
                                                        handleInputChange(e, false, services.id)
                                                    }
                                                    value={
                                                        servicelist &&
                                                        servicelist[services.id] &&
                                                        servicelist[services.id].tax_type
                                                    }
                                                    onBlur={checkEmptyValue}
                                                    disabled={getIsDisabled(services.id)}
                                                >
                                                    <option style={{ display: "none" }}></option>
                                                    <option value="EXM">Exempted</option>
                                                    <option value="FRAN">Franchise</option>
                                                    <option value="ISP">ISP</option>
                                                    <option value="SHR">Sharing</option>
                                                </Input>
                                                {/* <Label className="kyc_label">TAX *</Label> */}
                                                <span className="errortext">
                                                    {errors[`tax_type_${services.id}`]}
                                                </span>
                                            </div>
                                        </FormGroup>
                                    </td>
                                    <td style={{borderTable}}>
                                        <div className="input_wrap">
                                            <Input
                                                name={`isp_share_${services.id}`}
                                                onKeyDown={(evt) =>
                                                    (evt.key === "e" ||
                                                        evt.key === "E" ||
                                                        evt.key === "-") &&
                                                    evt.preventDefault()
                                                }
                                                min="0"
                                                pattern="^[0-9]\d{0,9}(\.\d{1,3})?%?$"
                                                // draft
                                                className={`form-control digits ${formData &&
                                                    formData.plans &&
                                                    formData.plans[`isp_share_${services.id}`]
                                                    ? "not-empty"
                                                    : ""
                                                    }`}
                                                value={
                                                    servicelist &&
                                                    servicelist[services.id] &&
                                                    servicelist[services.id].isp_share
                                                }
                                                type="number"
                                                disabled={getIsDisabled(services.id)}
                                                // disabled={
                                                //   !servicelist[services.id] ||
                                                //   !servicelist[services.id].selected ||
                                                //   !!servicelist[services.id].disabled
                                                // }

                                                onBlur={checkEmptyValue}
                                                onChange={(e) =>
                                                    handleInputChange(e, false, services.id)
                                                }
                                            />
                                            <span className="errortext">
                                                {errors[`isp_share_${services.id}`]}
                                            </span>
                                        </div>
                                    </td>
                                    <td style={{borderTable}}>
                                        <div className="input_wrap">
                                            <Input
                                                name={`franchise_share_${services.id}`}
                                                onKeyDown={(evt) =>
                                                    (evt.key === "e" ||
                                                        evt.key === "E" ||
                                                        evt.key === "-") &&
                                                    evt.preventDefault()
                                                }
                                                pattern="^[0-9]\d{0,9}(\.\d{1,3})?%?$"
                                                min="0"
                                                // draft
                                                className={`form-control digits ${formData &&
                                                    formData.plans &&
                                                    formData.plans[`franchise_share_${services.id}`]
                                                    ? "not-empty"
                                                    : ""
                                                    }`}
                                                value={
                                                    servicelist &&
                                                    servicelist[services.id] &&
                                                    servicelist[services.id].franchise_share
                                                }
                                                type="number"
                                                disabled={getIsDisabled(services.id)}
                                                onBlur={checkEmptyValue}
                                                onChange={(e) =>
                                                    handleInputChange(e, false, services.id)
                                                }
                                            />
                                            <span className="errortext">
                                                {errors[`franchise_share_${services.id}`]}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={() => {
                            handleClose();
                        }}
                        id="resetid"
                    >
                        {"Cancel"}
                    </Button>
                    <Button
                        color="primary"
                        className="save_button"
                        id="save_button_loader"
                        onClick={(e) => {
                            onSaveplans(e, lead.id);
                        }}
                        disabled={loaderSpinneer ? loaderSpinneer : loaderSpinneer}
                    >
                        {loaderSpinneer ? <Spinner size="sm"> </Spinner> : null} &nbsp;
                        {"Save"}
                    </Button>
                </ModalFooter>
                <Modal isOpen={alertModel} centered>
                    <ModalHeader>Confirmation</ModalHeader>
                    <ModalBody>
                        <p>{errorMsg}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setAlertModel(false)}>
                            Back
                        </Button>
                        {errorMsg !== "No changes were requested for franchise plans" && (
                            <Button color="primary" onClick={(e) => handleSubmit(e, lead.id)}>
                                Yes
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            </Modal>

            <Modal
                isOpen={selectserviceobjnew}
                toggle={AnotherToggle}
                centered
                style={{ maxWidth: "1000px" }}
            >
                <ModalBody style={{ maxHeight: "400px", overflow: "auto" }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ flex: 1 }}
                    ></Stack>
                    <div
                        style={{ textAlign: "center", color: "black", fontSize: "1.5em" }}
                    >
                        <h5 style={{ textAlign: "center !important" }}>Edit Plans</h5>
                    </div>
                    <Table className="table-border-vertical">
                        <thead>
                            <tr>
                                <th scope="col">{"Combo Plan Name"}</th>
                                <th scope="col" style={{ width: "22%" }}>
                                    {"Validity / Plan Cost"}
                                </th>
                                <th scope="col" style={{ width: "17%" }}>
                                    {"Tax *"}
                                </th>
                                <th scope="col">{"ISP Sharing (%)"}</th>
                                <th scope="col">{"Franchise Sharing (%)"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allOttPlanDetails
                                .filter(
                                    (service) =>
                                        servicelist[service.id] && servicelist[service.id].selected,
                                )
                                .map((services) => (
                                    <tr>

                                        <td scope="row">{services.combo_plan_name}</td>
                                        <td>
                                            {/* <tr> */}
                                                <span>
                                                    {services.plan_duration && `${services.plan_duration} mon(s)`}
                                                    {" / "}

                                                </span>

                                                <span>
                                                    {" "}
                                                    ₹ {parseFloat(services.total).toFixed(2)}
                                                </span>
                                            {/* </tr> */}
                                        </td>

                                        <td>
                                            <FormGroup>
                                                <div className="input_wrap">
                                                    <Input
                                                        type="select"
                                                        name={`tax_type_${services.id}`}
                                                        className={`form-control digits ${servicelist &&
                                                            servicelist[services.id] &&
                                                            servicelist[services.id].tax_type
                                                            ? "not-empty"
                                                            : "not-empty"
                                                            }`}
                                                        onChange={(e) =>
                                                            handleInputChange(e, false, services.id)
                                                        }
                                                        value={
                                                            servicelist &&
                                                            servicelist[services.id] &&
                                                            servicelist[services.id].tax_type
                                                        }
                                                        onBlur={checkEmptyValue}
                                                        // disabled={getIsDisabled(services.id)}/
                                                        disabled={isDisabled}
                                                    >
                                                        <option style={{ display: "none" }}></option>
                                                        <option value="EXM">Exempted</option>
                                                        <option value="FRAN">Franchise</option>
                                                        <option value="ISP">ISP</option>
                                                        <option value="SHR">Sharing</option>
                                                    </Input>
                                                    {/* <Label className="kyc_label">TAX *</Label> */}
                                                    <span className="errortext">
                                                        {errors[`tax_type_${services.id}`]}
                                                    </span>
                                                </div>
                                            </FormGroup>
                                        </td>
                                        <td>
                                            <div className="input_wrap">
                                                <Input
                                                    name={`isp_share_${services.id}`}
                                                    onKeyDown={(evt) =>
                                                        (evt.key === "e" ||
                                                            evt.key === "E" ||
                                                            evt.key === "-") &&
                                                        evt.preventDefault()
                                                    }
                                                    min="0"
                                                    pattern="^[0-9]\d{0,9}(\.\d{1,3})?%?$"
                                                    // draft
                                                    className={`form-control digits ${formData &&
                                                        formData.plans &&
                                                        formData.plans[`isp_share_${services.id}`]
                                                        ? "not-empty"
                                                        : ""
                                                        }`}
                                                    value={
                                                        servicelist &&
                                                        servicelist[services.id] &&
                                                        servicelist[services.id].isp_share
                                                    }
                                                    type="number"
                                                    disabled={isDisabled}
                                                    // disabled={getIsDisabled(services.id)}
                                                    // disabled={
                                                    //   !servicelist[services.id] ||
                                                    //   !servicelist[services.id].selected ||
                                                    //   !!servicelist[services.id].disabled
                                                    // }

                                                    onBlur={checkEmptyValue}
                                                    onChange={(e) =>
                                                        handleInputChange(e, false, services.id)
                                                    }
                                                />
                                                <span className="errortext">
                                                    {errors[`isp_share_${services.id}`]}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input_wrap">
                                                <Input
                                                    name={`franchise_share_${services.id}`}
                                                    onKeyDown={(evt) =>
                                                        (evt.key === "e" ||
                                                            evt.key === "E" ||
                                                            evt.key === "-") &&
                                                        evt.preventDefault()
                                                    }
                                                    pattern="^[0-9]\d{0,9}(\.\d{1,3})?%?$"
                                                    min="0"
                                                    // draft
                                                    className={`form-control digits ${formData &&
                                                        formData.plans &&
                                                        formData.plans[`franchise_share_${services.id}`]
                                                        ? "not-empty"
                                                        : ""
                                                        }`}
                                                    value={
                                                        servicelist &&
                                                        servicelist[services.id] &&
                                                        servicelist[services.id].franchise_share
                                                    }
                                                    type="number"
                                                    disabled={isDisabled}
                                                    // disabled={getIsDisabled(services.id)}
                                                    // disabled={
                                                    //   !servicelist[services.id] ||
                                                    //     !servicelist[services.id].selected ||
                                                    //     !!servicelist[services.id].disabled
                                                    // }

                                                    onBlur={checkEmptyValue}
                                                    onChange={(e) =>
                                                        handleInputChange(e, false, services.id)
                                                    }
                                                />
                                                <span className="errortext">
                                                    {errors[`franchise_share_${services.id}`]}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={() => {
                            handleCloseEdit();
                        }}
                        id="resetid"
                    >
                        {"Cancel"}
                    </Button>
                    <Button
                        color="primary"
                        className="save_button"
                        id="save_button_loader"
                        onClick={(e) => {
                            handleSubmitedit(e, lead.id);
                        }}
                        disabled={loaderSpinneer ? loaderSpinneer : loaderSpinneer}
                    >
                        {loaderSpinneer ? <Spinner size="sm"> </Spinner> : null} &nbsp;
                        {"Save"}
                    </Button>
                </ModalFooter>
            </Modal>
            {/* end */}
            <Container fluid={true} id="custinfo">
                <div>
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Search for Plan or Enter Amount"
                        onChange={(event) => handlesearchassignedplan(event)}
                        ref={searchInputField}
                        style={{
                            border: "1px solid #ced4da",
                            backgroundColor: "white",
                            width: "50%",
                            marginLeft: '-15px'
                        }}
                    />
                </div>
                <Form
                    onSubmit={(e) => {
                        handleSubmit(e, lead.id);
                    }}
                >
                    <br />
                    <Row>
                        <table style={tableStyles}>
                            <thead>
                                <tr>
                                    <th style={headerCellStyles}>Combo Plan Name</th>
                                    <th style={headerCellStyles}>Validity/Plan Cost</th>
                                    <th style={headerCellStyles}>Tax</th>
                                    <th style={headerCellStyles}>ISP Sharing</th>
                                    <th style={lastHeaderCellStyles}>Franchise Sharing</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ottPlanDetails?.map((plan, index) => (
                                    <tr key={index}>
                                        <td style={cellStyles}>
                                            {plan.plan_name}
                                        </td>
                                        <td style={cellStyles}>
                                            <div style={validityCostStyles}>
                                                {plan.plan_duration && `${plan.plan_duration} mon(s)`}
                                                {" / "}
                                                ₹ {parseFloat(plan.plan_cost).toFixed(2)}
                                            </div>
                                            {/* {plan.sub_plans?.map((subPlan, idx) => (
                                                <div key={idx} style={validityCostStyles}>
                                                    {`${parseFloat(subPlan.time_unit).toFixed(0)} ${subPlan.unit_type}(s)`}
                                                    {" / "}
                                                    ₹ {parseFloat(subPlan.plan_cost).toFixed(2)}
                                                </div>
                                            ))} */}
                                        </td>
                                        <td style={cellStyles}>
                                            {plan.tax_type === 'FRAN' ? 'Franchise' : plan.tax_type}
                                        </td>
                                        <td style={cellStyles}>
                                            {plan.isp_share}
                                        </td>
                                        <td style={lastCellStyles}>
                                            {plan.franchise_share}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                </Form>
            </Container>
        </Fragment>
    );
};

export default OTTAssignedPackage;
