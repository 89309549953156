import React, { useEffect } from 'react'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "../../../../mui/accordian";
import DataTable from 'react-data-table-component';
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ottaxios } from '../../../../axios';
import { useState } from 'react';
const OttPlainDetails = ({ username }) => {
    const [expanded, setExpanded] = React.useState("panel10");
    const [ottData, setOttData] = useState([]);
    const getOttDetails = async () => {
        const userDetails = await ottaxios.get(`/ott/get_subscriber_plan_details/${username}`);
        if (userDetails)
            setOttData([userDetails?.data?.data]);
    }
    const columns = [
        {
            name: <b className="cust_table_columns">{"Plan Name"}</b>,
            selector: "plan_name",
            sortable: true,
            cell: (row) => <span>{row?.plan_name}</span>,
        },
        {
            name: <b className="cust_table_columns">{"Queued Plan Name"}</b>,
            selector: "queued_plan_name",
            sortable: true,
            cell: (row) => <span>{row?.queued_plan_name}</span>,
        },
        {
            name: <b className="cust_table_columns">{"Status"}</b>,
            selector: "status",
            sortable: true,
            cell: (row) => <span>{row?.status}</span>,
        },
        {
            name: <b className="cust_table_columns">{"Duration"}</b>,
            selector: "plan_duration",
            sortable: true,
            cell: (row) => <span>{row?.plan_duration}</span>,
        },
        {
            name: <b className="cust_table_columns">{"Plan End Date"}</b>,
            selector: "expiry_date",
            sortable: true,
            cell: (row) => <span>{row?.expiry_date?.slice(0,10)}</span>,
        },

    ];

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        if (username) { getOttDetails() }
    }, [username])

    return (
        <>
            <Accordion
                style={{
                    borderRadius: "15px",
                    boxShadow: "0 0.2rem 1rem rgba(0, 0, 0, 0.15)",
                }}
                expanded={expanded === "panel10"}
                onChange={handleChange("panel10")}
            >
                <AccordionSummary
                    aria-controls="panel10a-content"
                    id="panel10a-header"
                    sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                    <Typography variant="h6" className="customerdetailsheading">
                        OTT
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {username && ottData ? (
                        <>
                            <DataTable
                                columns={columns}
                                data={ottData}
                                className="invoice_list"
                            />
                        </>
                    ) : (
                        <Box>
                            <Skeleton />
                            <Skeleton animation="wave" height={30} />{" "}
                            <Skeleton animation="wave" height={30} />{" "}
                            <Skeleton animation="wave" height={30} />{" "}
                            <Skeleton animation="wave" height={30} />{" "}
                            <Skeleton animation="wave" height={30} />{" "}
                            <Skeleton animation="wave" height={30} />{" "}
                            <Skeleton animation="wave" height={30} />
                            <Skeleton animation={false} />
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default OttPlainDetails