import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import OttIcon from "../../../../assets/images/ott-icon.png";

import useFormValidation from "../../../customhooks/FormValidation";
import { ottaxios } from "../../../../axios";

import ErrorModal from "../../../common/ErrorModal";

import ZEE5 from "../../../../assets/images/OTT ICONs/ZEE5@2x.png";
import ShemarooMe from "../../../../assets/images/OTT ICONs/Shemaroo@2x.png";
import NammaFlix from "../../../../assets/images/OTT ICONs/NammaFlix@2x.png";
import RajTv from "../../../../assets/images/OTT ICONs/Dummy.png";
import dollywoodPlay from "../../../../assets/images/OTT ICONs/Dollywood Play@2x.png";
import SonyLIV from "../../../../assets/images/OTT ICONs/Sony liv@2x.png";
import ShortsTV from "../../../../assets/images/OTT ICONs/shortTV@2x.png";
import PlayFlix from "../../../../assets/images/OTT ICONs/Playflix K-drama@2x.png";
import DocuBay from "../../../../assets/images/OTT ICONs/Docubay@2x.png";
import ptc from "../../../../assets/images/OTT ICONs/PTC Play@2x.png";
import FanCode from "../../../../assets/images/OTT ICONs/Fancode@2x.png";
import AAONXT from "../../../../assets/images/OTT ICONs/AAO NXT@2x.png";
import aha from "../../../../assets/images/OTT ICONs/Aha Telugu@2x.png";
import AhaTamil from "../../../../assets/images/OTT ICONs/Aha Tamil@2x.png";
import ALTT from "../../../../assets/images/OTT ICONs/ALTT@2x.png";
import ChaupalBhojpuri from "../../../../assets/images/OTT ICONs/Chaupal Bhojpuri@2x.png";
import chaupal from "../../../../assets/images/OTT ICONs/Chaupal@2x.png"
import CinemaWorld from "../../../../assets/images/OTT ICONs/Cinema World@2x.png";
import DangalPlay from "../../../../assets/images/OTT ICONs/Dangal Play@2x.png";
import DistroTV from "../../../../assets/images/OTT ICONs/Distro tv@2x.png";
import ETVWin from "../../../../assets/images/OTT ICONs/ETV@2x.png";
import istream from "../../../../assets/images/OTT ICONs/Istram@2x.png";
import KancchaLannka from "../../../../assets/images/OTT ICONs/Kanccha Lannka@2x.png";
import LIONSGATE from "../../../../assets/images/OTT ICONs/lionsgate play@2x.png";
import Manorama from "../../../../assets/images/OTT ICONs/Manorama Max@2x.png";
import OM from "../../../../assets/images/OTT ICONs/OM TV@2x.png";
import Powerkids from "../../../../assets/images/OTT ICONs/Powerkids Kartoon@2x.png";
import Bhaktiflix from "../../../../assets/images/OTT ICONs/sakthi Flix@2x.png";
import prag from "../../../../assets/images/OTT ICONs/Dummy.png";
import Premiumflix from '../../../../assets/images/OTT ICONs/Premiunflix@2x.png';
import Sanskar from "../../../../assets/images/OTT ICONs/Samskar@2x.png";
import Shortfundly from "../../../../assets/images/OTT ICONs/Shortfundly@2x.png";
import Stage from "../../../../assets/images/OTT ICONs/Stage@2x.png";
import SunNXT from "../../../../assets/images/OTT ICONs/SunNxt@2x.png";
import UltraJhakaas from "../../../../assets/images/OTT ICONs/ultra Jhakaas@2x.png";
import VROTT from "../../../../assets/images/OTT ICONs/Vr OTT@2x.png";
import ULLU from "../../../../assets/images/OTT ICONs/ULLU@2x.png";

const requiredFields = [
  "plan_name",
  "basic",
  "tax",
  "plan_duration",
  "status",
  "total",
  "operator_code"
];


const EditOttPlan = ({ lead, onUpdate, rightSidebar, plan }) => {
  const [operatorDetails, setOperatorDetails] = useState([]);

  const getOperatorDetails = async () => {
    const operator = await ottaxios.get("/ott/get_operator_details?export=true");
    if (operator?.data?.status === "success") {
      setOperatorDetails(operator?.data?.data?.result);
    }
  }
  useEffect(() => {
    getOperatorDetails();
  }, []);

  const {
    plan_name,
    plan_code,
    plan_duration,
    ott_services,
    status,
    basic,
    tax,
    total,
    operator_code
  } = plan;

  const selectedOperator = operatorDetails?.find(
    (operator) => operator.operator_code === operator_code
  );

  const operatorName = selectedOperator ? selectedOperator?.operator_name : '';

  const ottIcons = {
    ZEE5: ZEE5,
    ShemarooMe: ShemarooMe,
    NammaFlix: NammaFlix,
    "Raj TV": RajTv,
    "Dollywood Play": dollywoodPlay,
    SonyLIV: SonyLIV,
    ShortsTV: ShortsTV,
    PlayFlix: PlayFlix,
    DocuBay: DocuBay,
    "PTC Play": ptc,
    FanCode: FanCode,
    AAONXT: AAONXT,
    aha: aha,
    'aha Tamil': AhaTamil,
    ALTT: ALTT,
    "Chaupal Bhojpuri": ChaupalBhojpuri,
    CHAUPAL: chaupal,
    CinemaWorld: CinemaWorld,
    "Dangal Play": DangalPlay,
    DistroTV: DistroTV,
    "ETV Win": ETVWin,
    istream: istream,
    "Kanccha Lannka": KancchaLannka,
    "LIONSGATE PLAY": LIONSGATE,
    "Manorama Max": Manorama,
    "OM TV": OM,
    "Powerkids Plus Kartoon": Powerkids,
    Bhaktiflix: Bhaktiflix,
    "Prag Play": prag,
    Premiumflix: Premiumflix,
    Sanskar: Sanskar,
    Shortfundly: Shortfundly,
    Stage: Stage,
    SunNXT: SunNXT,
    ULLU: ULLU,
    "Ultra Jhakaas": UltraJhakaas,
    VROTT: VROTT,
  };

  const [loaderSpinneer, setLoaderSpinner] = useState(false);
  const [formData, setFormData] = useState({});
console.log(formData,'formData')
  useEffect(() => {
    setFormData({
      plan_name,
      plan_code,
      plan_duration,
      ott_services,
      status,
      basic,
      tax,
      total,
      operator_code: operatorName
    });
  }, [plan, operatorName]);

  const [errors, setErrors] = useState({});
  const [isDisabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [alertMessage, setAlertMessage] = useState(false);

  const openAlertMessage = () => setAlertMessage(!alertMessage);

  const handleInputChange = (e, isNumeric = false) => {
    setFormData({
      ...formData,
      [e.target.name]: isNumeric
        ? +Number(Math.abs(e.target.value)).toFixed(2)
        : e.target.value,
    });
  };

  useEffect(() => {
    if (!rightSidebar) {
      // resetformmanually();
      setErrors({});
    }
    setFormData((preState) => {
      return {
        ...preState,
        ...lead,
      };
    });
    setDisabled(true);
  }, [rightSidebar]);

  const updatePlan = (e) => {
    var config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    setLoaderSpinner(true);
    ottaxios
      .post("/ott/update_plan", formData, config)
      .then((res) => {
        if (res.data.status === "failure") {
          toast.error(`Error: ${res.data.message}`, { autoClose: 2000 });
        } else {
          toast.success("Plan Updated Successfully!", { autoClose: 2000 });
          onUpdate();
        }
        setLoaderSpinner(false);
      })
      .catch((error) => {
        setLoaderSpinner(false);
        if (error.response.data.message) {
          toast.error(error.response.data.message, { autoClose: 2000 });
          return;
        }
        let errorMessage = "Something went wrong";
        if (error.response && error.response.data) {
          if (error.response.data["package_name"].length > 0) {
            errorMessage = error.response.data["package_name"][0];
          } else if (error.response.status === 500) {
            errorMessage = "Internal server error";
          } else if (error.response.status === 404) {
            errorMessage = "API endpoint not found";
          } else if (error.response.status === 401) {
            errorMessage = "Unauthorized!";
          }
        }
        toast.error(errorMessage, { autoClose: 2000 });
      });
  };

  const resetForm = function () {
    setFormData({
      plan_name,
      plan_code,
      plan_duration,
      ott_services,
      status,
      basic,
      tax,
      total,
      operator_code: operatorName
    });
    setErrors({});
  };

  const form = useRef(null);

  useEffect(() => {
    const hasEmptyValue = requiredFields.some(
      (field) => formData[field] === "",
    );
    if (hasEmptyValue || Object.keys(errors).length) setDisabled(true);
    else setDisabled(false);
  }, [errors, formData]);

  // validations
  const submit = (e) => {
    e.preventDefault();
    e = e.target.name;

    const validationErrors = validate(formData);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);

    if (noErrors) {
      setAlertMessage(!alertMessage);
      updatePlan();
    } else {
      toast.error("Something went wrong");
    }
  };

  const { validate } = useFormValidation(requiredFields);

  const checkFieldValidity = (fieldName) => {
    const validationErrors = validate(formData);
    let vErrors = {};
    if (validationErrors[fieldName]) {
      vErrors[fieldName] = validationErrors[fieldName];
    }

    const noErrors = Object.keys(vErrors).length === 0;

    if (noErrors) {
      const newErrors = { ...errors };
      delete newErrors[fieldName];
      setErrors(newErrors);
    } else setErrors({ ...errors, ...{ [fieldName]: vErrors[fieldName] } });
  };

  function checkEmptyValue(e) {
    if (e.target.value == "") {
      e.target.classList.remove("not-empty");
    } else {
      e.target.classList.add("not-empty");
    }
  }

  //This function will be used for validation of individual fields
  const handleInputBlur = (e, fieldName) => {
    checkEmptyValue(e);
    checkFieldValidity(fieldName);
  };

  useEffect(() => {
    console.log("test====", formData);
    const { total } = formData;
    if (total) {
      setFormData((prev) => {
        const total = Number(prev.total);
        const basic = (total * 100) / 118;
        return {
          ...prev,
          tax: Number(total - basic).toFixed(2),
          basic: Number(basic).toFixed(2),
        };
      });
    } else if (total == 0) {
      setFormData((prev) => ({
        ...prev,
        tax: "",
        basic: "",
        total: "",
      }));
    }
  }, [formData.total]);



  return (
    <Fragment>
      <br />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form onSubmit={submit} id="myForm" onReset={resetForm} ref={form}>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Plan Name *</Label>
                      <Input
                        type="text"
                        name="plan_name"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "plan_name")}
                        className={`form-control digits ${formData && !formData.plan_name ? "" : "not-empty"
                          }`}
                        value={formData && formData.plan_name}
                      />
                    </div>

                    <span className="errortext">{errors.plan_name}</span>

                    <Row style={{ marginTop: "-5%", marginLeft: "-1px" }}>
                      <Col sm="12">
                        <ul>
                          <li
                            className="nas_field_strength"
                            style={{
                              marginLeft: "-18%",
                              top: "3px",
                              marginBottom: "2%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <b>Note : Only alphanumeric and "_" are allowed.</b>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Plan Code</Label>
                      <Input
                        type="text"
                        name="plan_code"
                        className={`form-control digits ${formData && !formData.plan_code ? "" : "not-empty"
                          }`}
                        value={formData && formData.plan_code}
                        disabled
                      />
                    </div>

                    <span className="errortext">{errors.plan_code}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Status *</Label>

                      <Input
                        type="select"
                        name="status"
                        className="form-control digits"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "status")}
                      >
                        <option
                          selected={formData?.status === "active"}
                          value="active"
                        >
                          ACTIVE
                        </option>
                        <option
                          selected={formData?.status === "inactive"}
                          value="inactive"
                        >
                          INACTIVE
                        </option>
                      </Input>
                    </div>
                    <span className="errortext">{errors.status}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Select Operator*</Label>
                      <Input
                        type="select"
                        name="operator_code"
                        className="form-control digits"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "operator_code")}
                      >
                        {operatorDetails?.map((elem, index) => (
                          <option selected={elem?.operator_name === operatorName} key={index} value={elem?.operator_code}>
                            {elem?.operator_name}
                          </option>
                        ))}
                      </Input>
                    </div>
                    {errors.operator_code && <span className="errortext">{errors.operator_code}</span>}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Total *</Label>
                      <Input
                        type="number"
                        name="total"
                        onChange={(e) => handleInputChange(e, true)}
                        onBlur={(e) => handleInputBlur(e, "total")}
                        className={`form-control digits ${formData && !formData.total ? "" : "not-empty"
                          }`}
                        min="0"
                        value={formData.total}
                      />
                    </div>

                    <span className="errortext">{errors.total}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Plan Cost *</Label>
                      <Input
                        type="number"
                        name="basic"
                        onChange={(e) => handleInputChange(e, true)}
                        onBlur={(e) => handleInputBlur(e, "basic")}
                        className={`form-control digits ${formData && !formData.basic ? "" : "not-empty"
                          }`}
                        min="0"
                        disabled
                        value={formData?.basic || ""}
                      />
                    </div>

                    <span className="errortext">{errors.basic}</span>
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">Tax *</Label>
                      <Input
                        type="number"
                        name="tax"
                        onChange={(e) => handleInputChange(e, true)}
                        onBlur={(e) => handleInputBlur(e, "tax")}
                        className={`form-control digits ${formData && !formData.tax ? "" : "not-empty"
                          }`}
                        min="0"
                        value={formData?.tax || ""}
                        disabled
                      />
                    </div>

                    <span className="errortext">{errors.tax}</span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {/* <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">OTT List</Label>
                      <Input
                        type="text"
                        name="ott_services"
                        className={`form-control digits ${
                          formData && !formData.ott_services ? "" : "not-empty"
                        }`}
                        value={formData && formData.ott_services}
                        disabled
                      />
                    </div>

                    <span className="errortext">{errors.ott_services}</span>
                  </FormGroup>
                </Col> */}
                <Col sm="3">
                  <FormGroup>
                    <div className="input_wrap">
                      <Label className="kyc_label">
                        Plan Duration (in months) *
                      </Label>
                      <Input
                        type="text"
                        name="plan_duration"
                        onChange={handleInputChange}
                        onBlur={(e) => handleInputBlur(e, "plan_duration")}
                        className={`form-control digits ${formData && !formData.plan_duration ? "" : "not-empty"
                          }`}
                        value={formData && formData.plan_duration}
                      />
                    </div>

                    <span className="errortext">{errors.plan_duration}</span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <div style={{ marginTop: 10, fontSize: "16px" }}>
                  <div>Ott Services</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginTop: 10,
                    }}
                  >
                    {(formData.ott_services || []).map((ott, idx) => (
                      <div
                        key={idx}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: 10,
                          marginRight: 20,
                        }}
                      >
                        <img
                          style={{
                            objectFit: "contain",
                            height: "35px",
                            width: "35px",
                          }}
                          src={ottIcons[ott]}
                          alt="ott-icon"
                        />
                        {ott}
                      </div>
                    ))}
                  </div>
                </div>
              </Row>
              <br />
              <Row>
                <span
                  className="sidepanel_border"
                  style={{ position: "relative" }}
                ></span>
                <Col>
                  <FormGroup className="mb-0">
                    <Button
                      color="btn btn-primary"
                      className="mr-3"
                      onClick={submit}
                      id="create_button"
                      disabled={loaderSpinneer ? loaderSpinneer : isDisabled}
                    >
                      {loaderSpinneer ? (
                        <Spinner size="sm" id="spinner"></Spinner>
                      ) : null}
                      Edit
                    </Button>

                    {/* <Button type="reset" color="btn btn-primary" id="resetid">
                      Reset
                    </Button> */}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <ErrorModal
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          message={modalMessage}
          action={() => setShowModal(false)}
        />
      </Container>
    </Fragment>
  );
};

export default EditOttPlan;
